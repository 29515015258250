import logo from './logo.svg';
import './App.css';
import MainInterface from './Views/MainInterface'

function App() {
  return (
    <div className="">
      <MainInterface />
    </div>
  );
}

export default App;
