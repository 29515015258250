import {
    CakeIcon,
    MountainIcon,
    ThermometerIcon,
    TimerIcon,
    ScaleIcon,
    BeakerIcon,
    CupSodaIcon,
    CandyCaneIcon,
    CakeSliceIcon,
    ToggleLeftIcon,
    ToggleRightIcon,
  } from "lucide-react";
  import React, { useState } from "react";
  import { render } from "react-dom";
  const HighAltitudeBakingTool = () => {
    const [targetAltitude, setTargetAltitude] = useState("");
    const [flour, setFlour] = useState("");
    const [sugar, setSugar] = useState("");
    const [liquid, setLiquid] = useState("");
    const [leavening, setLeavening] = useState("");
    const [ovenTemp, setOvenTemp] = useState("");
    const [bakingTime, setBakingTime] = useState("");
    const [adjustedRecipe, setAdjustedRecipe] = useState(null);
    const [useDryMetric, setUseDryMetric] = useState(true);
    const [useWetMetric, setUseWetMetric] = useState(true);

    const convertDry = (value, toMetric) => {
      return toMetric ? value * 120 : value / 120;
    };

    const convertWet = (value, toMetric) => {
      return toMetric ? value * 240 : value / 240;
    };

    const handleDryToggle = () => {
      setUseDryMetric(!useDryMetric);
      setFlour(prevFlour => convertDry(prevFlour, !useDryMetric).toFixed(2));
      setSugar(prevSugar => convertDry(prevSugar, !useDryMetric).toFixed(2));
      if (adjustedRecipe) {
        setAdjustedRecipe(prev => ({
          ...prev,
          flour: convertDry(prev.flour, !useDryMetric).toFixed(2),
          sugar: convertDry(prev.sugar, !useDryMetric).toFixed(2),
        }));
      }
    };

    const handleWetToggle = () => {
      setUseWetMetric(!useWetMetric);
      setLiquid(prevLiquid => convertWet(prevLiquid, !useWetMetric).toFixed(2));
      if (adjustedRecipe) {
        setAdjustedRecipe(prev => ({
          ...prev,
          liquid: convertWet(prev.liquid, !useWetMetric).toFixed(2),
        }));
      }
    };

    const calculateAdjustments = () => {
      const feetAbove3000 = Math.max(0, parseInt(targetAltitude) - 3000);
      const feetAbove3500 = Math.max(0, parseInt(targetAltitude) - 3500);
      
      const adjustedFlour = parseFloat(flour) + (feetAbove3500 / 3500) * (parseFloat(flour) / 16);
      const adjustedSugar = parseFloat(sugar) - (feetAbove3500 / 3500) * (parseFloat(sugar) / 16);
      const adjustedLiquid = parseFloat(liquid) + (parseInt(targetAltitude) / 1000) * (useWetMetric ? 15 : 0.0625);
      const adjustedLeavening = parseFloat(leavening) * (1 - (feetAbove3500 / 1000) * 0.15);
      const adjustedOvenTemp = parseInt(ovenTemp) + (feetAbove3000 / 1000) * 5;
      const adjustedBakingTime = parseFloat(bakingTime) * (1 - (parseInt(targetAltitude) / 1000) * 0.05);

      setAdjustedRecipe({
        flour: adjustedFlour.toFixed(2),
        sugar: adjustedSugar.toFixed(2),
        liquid: adjustedLiquid.toFixed(2),
        leavening: adjustedLeavening.toFixed(2),
        ovenTemp: adjustedOvenTemp.toFixed(0),
        bakingTime: adjustedBakingTime.toFixed(0),
      });
    };

    const theme = {
      bg: "bg-pink-200",
      text: "text-pink-900",
      button: "bg-teal-500 hover:bg-teal-600",
      input: "focus:ring-teal-500 focus:border-teal-500",
      label: "text-teal-600",
      toggle: {
        base: "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2",
        active: "bg-teal-500",
        inactive: "bg-gray-200",
        circle: "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
      },
    };
    const ToggleSwitch = ({ isOn, onToggle, label }) => (
      <div className="flex items-center justify-between py-2">
        <span className={`text-sm font-medium ${theme.label}`}>{label}</span>
        <button
          onClick={onToggle}
          className={`${theme.toggle.base} ${
            isOn ? theme.toggle.active : theme.toggle.inactive
          }`}
        >
          <span
            className={`${theme.toggle.circle} ${
              isOn ? "translate-x-5" : "translate-x-0"
            }`}
          />
        </button>
      </div>
    );
    return (
      <div className={`min-h-screen ${theme.bg}`}>
        <div className={`w-full max-w-4xl mx-auto p-6 rounded-3xl`}>
          <h1 className={`text-3xl font-bold text-center mb-8 ${theme.text}`}>
            🏔️ High-Altitude Baking Adjustment Tool 🍰
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-3xl shadow-md">
              <h2 className={`text-xl font-semibold mb-4 ${theme.text}`}>
                🌊 Sea Level Recipe
              </h2>
              <div className="space-y-4">
                <div>
                  <label className={`block text-sm font-medium ${theme.label}`}>
                    Target Altitude (feet) 🏔️
                  </label>
                  <div className="mt-1 relative rounded-full shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <MountainIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="number"
                      value={targetAltitude}
                      onChange={(e) => setTargetAltitude(e.target.value)}
                      className={`block w-full pl-10 sm:text-sm border-gray-300 rounded-full ${theme.input}`}
                      placeholder="e.g., 5000"
                    />
                  </div>
                </div>
                <div>
                  <label className={`block text-sm font-medium ${theme.label}`}>
                    Flour {useDryMetric ? '(g) 🌾' : '(cups) 🌾'}
                  </label>
                  <div className="mt-1 relative rounded-full shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <CakeIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="number"
                      value={flour}
                      onChange={(e) => setFlour(e.target.value)}
                      className={`block w-full pl-10 sm:text-sm border-gray-300 rounded-full ${theme.input}`}
                      placeholder={useDryMetric ? "e.g., 240" : "e.g., 2"}
                    />
                  </div>
                </div>
                <div>
                  <label className={`block text-sm font-medium ${theme.label}`}>
                    Sugar {useDryMetric ? '(g) 🍬' : '(cups) 🍬'}
                  </label>
                  <div className="mt-1 relative rounded-full shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <CandyCaneIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="number"
                      value={sugar}
                      onChange={(e) => setSugar(e.target.value)}
                      className={`block w-full pl-10 sm:text-sm border-gray-300 rounded-full ${theme.input}`}
                      placeholder={useDryMetric ? "e.g., 200" : "e.g., 1"}
                    />
                  </div>
                </div>
                <div>
                  <label className={`block text-sm font-medium ${theme.label}`}>
                    Liquid {useWetMetric ? '(mL) 💧' : '(cups) 💧'}
                  </label>
                  <div className="mt-1 relative rounded-full shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <CupSodaIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="number"
                      value={liquid}
                      onChange={(e) => setLiquid(e.target.value)}
                      className={`block w-full pl-10 sm:text-sm border-gray-300 rounded-full ${theme.input}`}
                      placeholder={useWetMetric ? "e.g., 120" : "e.g., 0.5"}
                    />
                  </div>
                </div>
                <div>
                  <label className={`block text-sm font-medium ${theme.label}`}>
                    Leavening Agent (tsp) 🧪
                  </label>
                  <div className="mt-1 relative rounded-full shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <ScaleIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="number"
                      value={leavening}
                      onChange={(e) => setLeavening(e.target.value)}
                      className={`block w-full pl-10 sm:text-sm border-gray-300 rounded-full ${theme.input}`}
                      placeholder="e.g., 1"
                    />
                  </div>
                </div>
                <div>
                  <label className={`block text-sm font-medium ${theme.label}`}>
                    Oven Temperature (°F) 🌡️
                  </label>
                  <div className="mt-1 relative rounded-full shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <ThermometerIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="number"
                      value={ovenTemp}
                      onChange={(e) => setOvenTemp(e.target.value)}
                      className={`block w-full pl-10 sm:text-sm border-gray-300 rounded-full ${theme.input}`}
                      placeholder="e.g., 350"
                    />
                  </div>
                </div>
                <div>
                  <label className={`block text-sm font-medium ${theme.label}`}>
                    Baking Time (minutes) ⏱️
                  </label>
                  <div className="mt-1 relative rounded-full shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <TimerIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="number"
                      value={bakingTime}
                      onChange={(e) => setBakingTime(e.target.value)}
                      className={`block w-full pl-10 sm:text-sm border-gray-300 rounded-full ${theme.input}`}
                      placeholder="e.g., 30"
                    />
                  </div>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg mt-6">
                  <h3 className={`text-sm font-medium mb-2 ${theme.label}`}>Measurement Units</h3>
                  <ToggleSwitch
                    isOn={useDryMetric}
                    onToggle={handleDryToggle}
                    label="Use Metric (g) for Dry Ingredients"
                  />
                  <ToggleSwitch
                    isOn={useWetMetric}
                    onToggle={handleWetToggle}
                    label="Use Metric (mL) for Wet Ingredients"
                  />
                </div>
              </div>
              <button
                onClick={calculateAdjustments}
                className={`mt-6 w-full border border-transparent rounded-full py-3 px-8 flex items-center justify-center text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${theme.button}`}
              >
                Generate Recipe 🍰
              </button>
            </div>
            <div className="bg-white p-6 rounded-3xl shadow-md">
              <h2 className={`text-xl font-semibold mb-4 ${theme.text}`}>
                🏔️ High-Altitude Adjusted Recipe
              </h2>
              {adjustedRecipe ? (
                <div className="space-y-4">
                  <div>
                    <span className="font-medium">Flour 🌾:</span>{" "}
                    {adjustedRecipe.flour} {useDryMetric ? 'g' : 'cups'}
                  </div>
                  <div>
                    <span className="font-medium">Sugar 🍬:</span>{" "}
                    {adjustedRecipe.sugar} {useDryMetric ? 'g' : 'cups'}
                  </div>
                  <div>
                    <span className="font-medium">Liquid 💧:</span>{" "}
                    {adjustedRecipe.liquid} {useWetMetric ? 'mL' : 'cups'}
                  </div>
                  <div>
                    <span className="font-medium">Leavening Agent 🧪:</span>{" "}
                    {adjustedRecipe.leavening} tsp
                  </div>
                  <div>
                    <span className="font-medium">Oven Temperature 🌡️:</span>{" "}
                    {adjustedRecipe.ovenTemp}°F
                  </div>
                  <div>
                    <span className="font-medium">Baking Time ⏱️:</span>{" "}
                    {adjustedRecipe.bakingTime} minutes
                  </div>
                </div>
              ) : (
                <p className="text-gray-500">
                  Enter your sea level recipe details and calculate to see
                  high-altitude adjustments. 📝
                </p>
              )}
            </div>
          </div>
          <div className="mt-8 bg-white p-6 rounded-3xl shadow-md">
            <h2 className={`text-xl font-semibold mb-4 ${theme.text}`}>
              Why Adjust for High Altitude? 🤔
            </h2>
            <p className="text-gray-700">
              Baking at high altitudes requires adjustments due to lower air
              pressure. This affects how ingredients interact and how quickly
              moisture evaporates. Our tool helps you:
            </p>
            <ul className="list-disc list-inside mt-2 space-y-2 text-gray-700">
              <li>
                Increase oven temperature to set structure before over-expansion
                🌡️
              </li>
              <li>Decrease baking time to prevent drying ⏱️</li>
              <li>Adjust sugar and flour for proper structure 🍬</li>
              <li>Increase liquids to compensate for faster evaporation 💧</li>
              <li>Decrease leavening agents to control rise 🧪</li>
            </ul>
          </div>
        </div>
        <div className="text-center text-gray-500">
            Baked with ❤️ in Denver, CO 
        </div>
      </div>
    );
  };
  export default HighAltitudeBakingTool;  
